$radius-sm: 5px;
$radius-md: 10px;
$radius-lg: 25px;

// Primary
$primary-500: #e2001a;

// Greys
$grey-800: #333333;
$grey-700: #444444;
$grey-600: #666666;
$grey-500: #999999;
$grey-400: #d7d7d7;
$grey-300: #dddddd;
$grey-200: #f5f5f5;

// Success
$success-500: #52c41a;

// Error
$error-500: #f5222d;

// Neutral
$white: #fff;

// Misc
$bg-footer: #f7f9ff;

$space-xs: 5px;
$space-sm: 10px;
$space-md: 15px;
$space-lg: 20px;
$space-xl: 30px;
$space-2xl: 60px;

$primary-shadow: 0 5px 15px rgba(0, 0, 0, 0.11);
$secondary-shadow: 0 0 16px 0 rgba(235, 235, 235, 0.69);

$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-2xl: 24px;

$font-normal: 400;
$font-medium: 500;
$font-bold: bold;

.wrapper {
  background: $white;
  position: relative;
  z-index: 10;
  :global(.ant-menu) {
    font-size: $font-md;
  }

  :global(.left-sider-logo) {
    padding: $space-sm $space-md;
  }

  :global(.left-sider-toggle) {
    transform: rotateZ(90deg);
    color: $grey-600;
    font-size: $font-lg;

    &:global(.collapse) {
      transform: rotateZ(-90deg);
    }
  }

  :global(.left-sider-opt) {
    padding: $space-lg $space-md;
  }

  :global(.country-setting-button) {
    display: flex;
    align-items: center;
    gap: $space-lg;
    padding-left: $space-sm;
  }

  :global(.wrapper-hover) {
    &:hover {
      cursor: pointer;
      color: $primary-500;
    }
  }

  .menu {
    flex: 1;
    overflow: scroll;
    overflow-x: hidden;
    padding-top: $space-sm;

    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 0;
      background: rgba(0, 4, 40, 0.06);
    }
    &:global(
        .ant-menu-inline.ant-menu-root
          .ant-menu-submenu-title
          > .ant-menu-title-content
      ) {
      font-size: $font-md;
      font-weight: $font-medium;
    }
  }

  .LeftOutlined-box {
    position: absolute;
    top: calc(50% - $space-xl);
    right: -$space-sm;
    width: 15px;
    height: 60px;
    display: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-top-right-radius: $radius-sm;
    border-bottom-right-radius: $radius-sm;
    background-color: $grey-800;
    color: $white;
    font-size: $font-xs;
    &:hover {
      box-shadow: $primary-shadow;
    }
  }

  &:hover {
    .LeftOutlined-box {
      display: flex;
    }
  }
}

:global(.ant-menu-submenu-popup .ant-menu .ant-menu-item) {
  display: flex;
  align-items: center;
}

:global(.country-setting-content) {
  position: relative;
  margin: 0;
  padding: $space-xs 0;
  text-align: left;
  background-color: $white;
  background-clip: padding-box;
  border-radius: $radius-sm;
  min-width: 140px;
  box-shadow: $primary-shadow;

  & > li {
    padding: $space-sm $space-lg;
    cursor: pointer;

    &:hover {
      color: $primary-500;
      background-color: $grey-200;
    }

    &:global(.active) {
      color: $primary-500;
      background-color: $grey-300;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  font-size: $font-md;
}

.menu-icon {
  font-size: $font-xl;
}
.menu-child-icon {
  font-size: $font-md;
}
.title {
  font-weight: $font-medium;
}
