// antd样式
@import "~antd/dist/antd.less";

@font-face {
  font-family: DINPro;
  src: url(./fonts/DINPro-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: DINPro;
  src: url(./fonts/DINPro-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: DINPro;
  src: url(./fonts/DINPro-Medium.otf);
  font-weight: 700;
}

@font-face {
  font-family: DINPro;
  src: url(./fonts/DINPro-Medium.otf);
  font-weight: bold;
}

@primary-color: #e2001a;@border-radius-base: 5px;