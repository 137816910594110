$radius-sm: 5px;
$radius-md: 10px;
$radius-lg: 25px;

// Primary
$primary-500: #e2001a;

// Greys
$grey-800: #333333;
$grey-700: #444444;
$grey-600: #666666;
$grey-500: #999999;
$grey-400: #d7d7d7;
$grey-300: #dddddd;
$grey-200: #f5f5f5;

// Success
$success-500: #52c41a;

// Error
$error-500: #f5222d;

// Neutral
$white: #fff;

// Misc
$bg-footer: #f7f9ff;

$space-xs: 5px;
$space-sm: 10px;
$space-md: 15px;
$space-lg: 20px;
$space-xl: 30px;
$space-2xl: 60px;

$primary-shadow: 0 5px 15px rgba(0, 0, 0, 0.11);
$secondary-shadow: 0 0 16px 0 rgba(235, 235, 235, 0.69);

$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-2xl: 24px;

$font-normal: 400;
$font-medium: 500;
$font-bold: bold;

.wrapper {
  padding: 0 $space-xl !important;
  background-color: $white !important;
  border-bottom: 1px $grey-300 solid;
}

.country-menu {
  :global(.ant-dropdown-menu-title-content) {
    display: flex;
    align-items: center;
  }
}
.user-menu {
  padding: $space-sm $space-lg;
}
.container {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.nav-wrapper {
  display: flex;
  align-items: center;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.store-link {
  display: flex;
  align-items: center;
  margin-right: $space-lg;
  color: $grey-700;
}

.shop-icon {
  display: block;
  font-size: $font-md;
  cursor: pointer;
  &:hover {
    box-shadow: $primary-shadow;
  }
}

.country-dropdown {
  display: flex;
  align-items: center;
  padding-right: $space-sm;
}

.country-icon {
  font-size: $font-xl;
  cursor: pointer;
  &:hover {
    box-shadow: $primary-shadow;
  }
}
.country-name {
  padding-left: $space-sm;
}
.user-dropdown {
  cursor: pointer;
}
.user-dropdown-container {
  display: flex;
  align-items: center;
}

.username {
  width: 100px;
  padding: 0 $space-sm;
  overflow: hidden;
  font-size: $font-md;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}

.flags-dropdown {
  :global(.ant-dropdown-menu) {
    max-height: 232px;
    display: grid;
    grid-template-rows: repeat(7, auto);
    grid-auto-flow: column;
  }
}
.env {
  margin-right: $space-md;
}
