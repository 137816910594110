$radius-sm: 5px;
$radius-md: 10px;
$radius-lg: 25px;

// Primary
$primary-500: #e2001a;

// Greys
$grey-800: #333333;
$grey-700: #444444;
$grey-600: #666666;
$grey-500: #999999;
$grey-400: #d7d7d7;
$grey-300: #dddddd;
$grey-200: #f5f5f5;

// Success
$success-500: #52c41a;

// Error
$error-500: #f5222d;

// Neutral
$white: #fff;

// Misc
$bg-footer: #f7f9ff;

$space-xs: 5px;
$space-sm: 10px;
$space-md: 15px;
$space-lg: 20px;
$space-xl: 30px;
$space-2xl: 60px;

$primary-shadow: 0 5px 15px rgba(0, 0, 0, 0.11);
$secondary-shadow: 0 0 16px 0 rgba(235, 235, 235, 0.69);

$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-2xl: 24px;

$font-normal: 400;
$font-medium: 500;
$font-bold: bold;

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  width: 100%;
  height: 100%;
}
.App {
  width: 100%;
  height: 100%;
  font-family: DINPro, "RC TYPE", Roboto, Avenir, Helvetica, Arial, sans-serif;
  color: $grey-700;
}

menu,
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
// ANTD override
.ant-table table {
  border-radius: $radius-md $radius-md 0 0 !important;

  td {
    color: $grey-600;
    font-size: $font-sm;
  }
}

.ant-table-row:nth-child(odd) {
  td {
    background-color: $grey-200;
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    background-color: $grey-200;
  }
}

.ant-table .ant-table-thead > tr > th {
  color: $white;
  font-size: $font-sm;
  background-color: $grey-700;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: $radius-md !important;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: $radius-md !important;
}
.ant-table .ant-table-thead th.ant-table-column-has-sorters:hover {
  color: $white;
  background-color: $grey-700;
}

div.ant-tabs-tab {
  color: $grey-600;
  font-size: $font-lg;
  font-weight: $font-normal;
}
div.ant-tabs-tab-active {
  font-weight: $font-medium;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  ):after {
  color: $primary-500;
  content: "*";
  display: inline-block;
  font-family: SimSun, sans-serif;
  font-size: $font-sm;
  line-height: 1;
  margin-right: $space-xs;
}
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  ):before {
  content: "" !important;
  display: none !important;
}
.ant-form-item-label > label,
.ant-pro-form-group-title {
  color: $grey-600 !important;
  font-weight: $font-medium !important;
}

.anticon {
  vertical-align: middle !important;
}

div.ant-modal-content {
  border-radius: $radius-md;
}

.ant-tooltip {
  font-feature-settings: "tnum", "tnum";
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  display: block;
  font-size: $font-sm;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  max-width: 250px;
  padding: 0;
  position: absolute;
  visibility: visible;
  width: -moz-max-content;
  width: max-content;
  width: intrinsic;
  z-index: 1070;
}

.ant-tooltip .ant-tooltip-arrow-content,
.ant-tooltip .ant-tooltip-inner {
  background-color: $primary-500;
}
