$radius-sm: 5px;
$radius-md: 10px;
$radius-lg: 25px;

// Primary
$primary-500: #e2001a;

// Greys
$grey-800: #333333;
$grey-700: #444444;
$grey-600: #666666;
$grey-500: #999999;
$grey-400: #d7d7d7;
$grey-300: #dddddd;
$grey-200: #f5f5f5;

// Success
$success-500: #52c41a;

// Error
$error-500: #f5222d;

// Neutral
$white: #fff;

// Misc
$bg-footer: #f7f9ff;

$space-xs: 5px;
$space-sm: 10px;
$space-md: 15px;
$space-lg: 20px;
$space-xl: 30px;
$space-2xl: 60px;

$primary-shadow: 0 5px 15px rgba(0, 0, 0, 0.11);
$secondary-shadow: 0 0 16px 0 rgba(235, 235, 235, 0.69);

$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-2xl: 24px;

$font-normal: 400;
$font-medium: 500;
$font-bold: bold;

.container {
  align-items: center;
}
.success {
  padding: $space-sm 0;
  font-size: $space-md;
  font-weight: $font-medium;
}
.message {
  padding: $space-sm 0;
  font-size: $space-md;
}
.ok-button {
  display: none !important;
}
.cancel-button {
  width: 100%;
}
.html {
  display: inline-flex;
  font-size: $space-md;
  font-weight: $font-medium;
}

.check  {
  font-size: $font-md !important;
  margin: $space-sm 0 !important;
  color: $success-500 !important;
}

.close {
  font-size: $font-md !important;
  margin: $space-sm 0 !important;
  color: $error-500 !important;
}

.rc-desktop-modal {
  width: auto !important;
  min-width: 360px;
  max-width: 800px !important;
  :global(.ant-modal-content) {
    overflow: hidden;
  }
  :global(.ant-modal-body) {
    padding: 0 !important;
  }
  :global(.ant-modal-close-x) {
    width: 46px;
    height: 46px;
    line-height: 46px;
    color: $white;
  }
  :global(.ant-modal-confirm-title) {
    padding: $space-sm $space-md;
    background-color: $primary-500;
    color: $white;
  }
  :global(.ant-modal-confirm-content) {
    padding: $space-sm $space-md $space-xs;
  }
}

@media screen and (max-width: 575px) {
  .rc-mobile-modal {
    :global(.ant-modal-content) {
      border-radius: $radius-md;
    }
    :global(.ant-modal-body) {
      padding: 0;
    }
    :global(.ant-modal-confirm-body) {
      padding: 0 $space-md;
    }
    :global(.ant-modal-confirm-btns) {
      float: none;
      margin-top: $space-sm;
      border-top: 1px solid $grey-200;
    }
  }
}
