$radius-sm: 5px;
$radius-md: 10px;
$radius-lg: 25px;

// Primary
$primary-500: #e2001a;

// Greys
$grey-800: #333333;
$grey-700: #444444;
$grey-600: #666666;
$grey-500: #999999;
$grey-400: #d7d7d7;
$grey-300: #dddddd;
$grey-200: #f5f5f5;

// Success
$success-500: #52c41a;

// Error
$error-500: #f5222d;

// Neutral
$white: #fff;

// Misc
$bg-footer: #f7f9ff;

$space-xs: 5px;
$space-sm: 10px;
$space-md: 15px;
$space-lg: 20px;
$space-xl: 30px;
$space-2xl: 60px;

$primary-shadow: 0 5px 15px rgba(0, 0, 0, 0.11);
$secondary-shadow: 0 0 16px 0 rgba(235, 235, 235, 0.69);

$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-2xl: 24px;

$font-normal: 400;
$font-medium: 500;
$font-bold: bold;

.content {
  background-color: $white;
  padding: $space-xl;
  border-radius: $radius-md;
  margin-top: $space-lg;

  :global(.ant-typography) {
    color: $primary-500;
  }

  :global(.edit-icon .anticon) {
    font-size: $font-2xl;
    fill: $primary-500;
  }

  :global(.shorten .ant-descriptions-item-content) {
    overflow: hidden;
    white-space: nowrap;
    display: inline;
    max-width: 900px;
    text-overflow: ellipsis;
  }
  &.last {
    margin-right: $space-lg;
    display: flex;
    justify-content: flex-end;
  }
}
.row {
  margin-top: $space-sm;
  width: 100%;
}
.datepicker {
  width: 100%;
}

.file-form {
  display: flex;
  justify-content: space-between;
}
.file-input {
  margin-right: $space-lg;
}
.file-preview {
  width: 200px;
  margin-top: $space-md;
  height: auto;
}
.pet-edit-header {
  border-left: 2px solid $primary-500;
  margin-bottom: $space-lg;
  background-color: $white;
  align-items: center;
  border-top-right-radius: $radius-md;
  border-bottom-right-radius: $radius-md;

  .pet-edit-main {
    display: flex;
    justify-content: space-between;
    background-color: $white;
    border-top-right-radius: $radius-md;
    border-bottom-right-radius: $radius-md;
    padding: $space-xl;
  }

  .pet-edit-name {
    color: $primary-500;
    font-size: $font-2xl;
  }

  .pet-edit-infos {
    display: flex;
    gap: $space-xs;
    align-items: center;
  }

  .pet-edit-title {
    font-weight: $font-medium;
    color: $grey-600;
    font-size: $font-md;
  }
}
.back {
  display: flex;
  justify-content: space-between;
  margin-right: $space-md;
  align-items: center;
}
.back-icon {
  padding-right: $space-xs;
  display: inline-block;
}
.back-link {
  text-decoration: underline;
  font-weight: $font-medium;
  cursor: pointer;
}
