$radius-sm: 5px;
$radius-md: 10px;
$radius-lg: 25px;

// Primary
$primary-500: #e2001a;

// Greys
$grey-800: #333333;
$grey-700: #444444;
$grey-600: #666666;
$grey-500: #999999;
$grey-400: #d7d7d7;
$grey-300: #dddddd;
$grey-200: #f5f5f5;

// Success
$success-500: #52c41a;

// Error
$error-500: #f5222d;

// Neutral
$white: #fff;

// Misc
$bg-footer: #f7f9ff;

$space-xs: 5px;
$space-sm: 10px;
$space-md: 15px;
$space-lg: 20px;
$space-xl: 30px;
$space-2xl: 60px;

$primary-shadow: 0 5px 15px rgba(0, 0, 0, 0.11);
$secondary-shadow: 0 0 16px 0 rgba(235, 235, 235, 0.69);

$font-xs: 12px;
$font-sm: 14px;
$font-md: 16px;
$font-lg: 18px;
$font-xl: 20px;
$font-2xl: 24px;

$font-normal: 400;
$font-medium: 500;
$font-bold: bold;

.wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
}

.spinner {
  position: absolute !important;
  left: 50%;
  top: 40%;
}
